import React from "react";
import { useEffect, useRef } from "react";

import { motion, useInView, useAnimation, useIsPresent } from "framer-motion";

import SlideComp from './SlideComp.js'

import './ProjectSlide.css'

import Astor_0 from '../../assets/project/1_col_off/GraphicDesign/Astor/00.jpg'
import Astor_1 from '../../assets/project/1_col_off/GraphicDesign/Astor/01.jpg'

import Corrade_0 from '../../assets/project/1_col_off/GraphicDesign/Corrade/00.jpg'

import Surv_0 from '../../assets/project/1_col_off/GraphicDesign/Surv/00.jpg'
import Surv_1 from '../../assets/project/1_col_off/GraphicDesign/Surv/01.jpg'
import Surv_2 from '../../assets/project/1_col_off/GraphicDesign/Surv/02.jpg'
import Surv_3 from '../../assets/project/1_col_off/GraphicDesign/Surv/03.jpg'

import UnderOneRoof_0 from '../../assets/project/1_col_off/GraphicDesign/UnderOneRoof/00.jpg'
import UnderOneRoof_1 from '../../assets/project/1_col_off/GraphicDesign/UnderOneRoof/01.jpg'
import UnderOneRoof_2 from '../../assets/project/1_col_off/GraphicDesign/UnderOneRoof/02.jpg'

import WilliamsCollege_0 from '../../assets/project/1_col_off/GraphicDesign/WilliamsCollege/00.jpg'
import WilliamsCollege_1 from '../../assets/project/1_col_off/GraphicDesign/WilliamsCollege/01.jpg'
import WilliamsCollege_2 from '../../assets/project/1_col_off/GraphicDesign/WilliamsCollege/02.jpg'



const Astor = [
    { image: Astor_0},
    { image: Astor_1},
  ];

const Corrade = [
    { image: Corrade_0},
  ];

const Surv = [
    { image: Surv_0},
    { image: Surv_1},
    { image: Surv_2},
    { image: Surv_3},
  ];

const UnderOneRoof = [
    { image: UnderOneRoof_0},
    { image: UnderOneRoof_1},
    { image: UnderOneRoof_2},
  ];

const WilliamsCollege = [
    { image: WilliamsCollege_0},
    { image: WilliamsCollege_1},
    { image: WilliamsCollege_2},
  ];

  

const Graphic = () => {
    
    
    //  Animation
    const ref = useRef(null);
    const isInView = useInView(ref, {once: true});

    const mainControls = useAnimation();

    useEffect(() => {if (isInView){
        mainControls.start("visible")
    }}, [isInView])
    //  Animation


    return(
        <div className="section_container">
            <div className="grid_container_slider">
                    <SlideComp slideData={Surv} client = 'Surv' project = 'Visual identity' cursor = 'dark'></SlideComp>
                    <SlideComp slideData={UnderOneRoof} client = 'Under One Roof' project = 'Visual identity' cursor = 'light'></SlideComp>
                    <SlideComp slideData={Astor} client = 'Astor' project = 'Type design' cursor = 'light'></SlideComp>
                    <SlideComp slideData={WilliamsCollege} client = 'Williams College' project = 'Type design' cursor = 'light'></SlideComp>
                    <SlideComp slideData={Corrade} client = 'Corrade' project = 'Type design' cursor = 'light'></SlideComp>
                <motion.div className="slide"
                    ref={ref}
                    variants={{
                        hidden: {opacity:0, y:25},
                        visible: {opacity:1, y:0}
                    }}
                    initial="hidden"
                    animate={mainControls}
                    transition={{duration:0.5, delay:0.2}}>        
                </motion.div>
            </div>
        </div>

    )
}


export default Graphic