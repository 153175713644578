import React from "react";
import { useEffect, useRef } from "react";

import { motion, useInView, useAnimation, useIsPresent } from "framer-motion";

import SlideComp from './SlideComp.js'

import './ProjectSlide.css'


import ShaftAngle_0 from '../../assets/project/1_col_off/Archives/ShaftAngle/00.mp4'

import Tangiblen_0 from '../../assets/project/1_col_off/Archives/Tangiblen/00.mp4'
import Tangiblen_1 from '../../assets/project/1_col_off/Archives/Tangiblen/01.mp4'
import Tangiblen_2 from '../../assets/project/1_col_off/Archives/Tangiblen/02.jpg'
import Tangiblen_3 from '../../assets/project/1_col_off/Archives/Tangiblen/03.jpg'

import TangibleType_0 from '../../assets/project/1_col_off/Archives/TangibleType/00.jpg'
import TangibleType_1 from '../../assets/project/1_col_off/Archives/TangibleType/01.jpg'
import TangibleType_2 from '../../assets/project/1_col_off/Archives/TangibleType/02.mp4'
import TangibleType_3 from '../../assets/project/1_col_off/Archives/TangibleType/03.mp4'





const ShaftAngle = [
    { video: ShaftAngle_0},
  ];

const Tangiblen = [
    { video: Tangiblen_0},
    // { video: Tangiblen_1},
    { image: Tangiblen_2},
    { image: Tangiblen_3},
  ];

const TangibleType = [
    { image: TangibleType_0},
    { image: TangibleType_1},
    { video: TangibleType_2},
    { video: TangibleType_3},
  ];

  

const Archives = () => {
    
    
    //  Animation
    const ref = useRef(null);
    const isInView = useInView(ref, {once: true});

    const mainControls = useAnimation();

    useEffect(() => {if (isInView){
        mainControls.start("visible")
    }}, [isInView])
    //  Animation


    return(
        <div className="section_container">
            <div className="grid_container_slider">
                    <SlideComp slideData={ShaftAngle} client = 'Self-initiated' project = 'Untitled' cursor = 'light'></SlideComp>
                    <SlideComp slideData={TangibleType} client = 'Self-initiated' project = 'Tangible Type' cursor = 'light'></SlideComp>
                    <SlideComp slideData={Tangiblen} client = 'Self-initiated' project = 'Tangible n' cursor = 'light'></SlideComp>
                <motion.div className="slide"
                    ref={ref}
                    variants={{
                        hidden: {opacity:0, y:25},
                        visible: {opacity:1, y:0}
                    }}
                    initial="hidden"
                    animate={mainControls}
                    transition={{duration:0.5, delay:0.2}}>        
                </motion.div>
            </div>
        </div>

    )
}


export default Archives