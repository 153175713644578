import React from "react";
import { useState } from "react";
import './Projects.css'

import CustomLink from './CustomLink.js'

import Product from './Projects_1_col_off/Product'
import Graphic from './Projects_1_col_off/Graphic'
import Archives from './Projects_1_col_off/Archives'

import { Route, Routes, Navigate } from "react-router-dom";

import { useEffect, useRef } from "react";
import { motion, useInView, useAnimation, useIsPresent } from "framer-motion";


const Projects = () => {

    //  Tabs
    const [ToggleState, setToggleState] = useState('product');

    const toggleTab = (index) => {
        setToggleState(index);
    }
    //  Tabs

    //  Animation
    const ref = useRef(null);
    const isInView = useInView(ref, {once: true});

    const mainControls = useAnimation();

    useEffect(() => {if (isInView){
        mainControls.start("visible")
    }}, [isInView])
    //  Animation

    return(
        <>

            <div className="section_container">
                <motion.div className="grid_container"
                    ref={ref}
                    variants={{
                        hidden: {opacity:0, y:25},
                        visible: {opacity:1, y:0}
                    }}
                    initial="hidden"
                    animate={mainControls}
                    transition={{duration:0.5, delay:0.2}}>

                    <div className="title col_1_4">Projects</div>
                    <div className="copy col_5_12">


                        <CustomLink to ='/'>Product Design</CustomLink>
                        <CustomLink to ='/Graphic'>Graphic Design</CustomLink>
                        <CustomLink to ='/Archives'>Archives</CustomLink>

                    </div>
    

                </motion.div>
            </div>


            <Routes>
                    <Route path='/' element={<Product/>} />
                    <Route path='/Graphic' element={<Graphic/>} />
                    <Route path='/Archives' element={<Archives/>} />
                    <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>


        </>
    )
}


export default Projects
