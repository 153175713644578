import React from "react";
import { useState, useEffect, useRef } from "react";

import { motion, useInView, useAnimation } from "framer-motion";

import Slider from "react-slick"

import './SlideComp.css'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";




const SlideComp = (props) => {
    
    const slideData = props.slideData;
    const client = props.client;
    const project = props.project;
    
    let cursor_prev;
    let cursor_next;
    
    if(props.cursor == 'light'){
        cursor_prev = 'custom_cursor_prev_light';
        cursor_next = 'custom_cursor_next_light';
    }else{
        cursor_prev = 'custom_cursor_prev_dark';
        cursor_next = 'custom_cursor_next_dark';
    }

    const sliderRef = useRef(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [cursorStyle, setcursorStyle] = useState('default');
    const [posYValue, setPosYValue] = useState(0);

    const posY = (e) => {
        let pos_y = window.scrollY;
        setPosYValue(pos_y);
    }

    const handleSlideClick = (e) => {

        if (!sliderRef.current) return;

        const sliderNode = sliderRef.current.innerSlider.list;
        const rect = sliderNode.getBoundingClientRect();
        const clickX = e.clientX - rect.left;
        const slideWidth = rect.width / settings.slidesToShow;

        const length = sliderNode.children[0].children.length;
    

        if(length > 1){

            if (clickX < slideWidth / 2) {

                if({currentSlide}.currentSlide == 0){

                    sliderRef.current.slickNext(); // Go to the next slide
                    window.scrollTo(0,  posYValue );

                }else{

                    sliderRef.current.slickPrev(); // Go to the previous slide
                    window.scrollTo(0, posYValue );

                }

            } else {

                if({currentSlide}.currentSlide >= length - 1){

                    sliderRef.current.slickPrev(); // Go to the previous slide
                    window.scrollTo(0, posYValue);

                }else{

                    sliderRef.current.slickNext(); // Go to the next slide
                    window.scrollTo(0, posYValue);

                }

                if({currentSlide}.currentSlide >= length - 2){

                    setcursorStyle(cursor_prev);

                }
            }


            //hover update on click
            if (clickX < slideWidth / 2) {

                if({currentSlide}.currentSlide == 0){

                    setcursorStyle(cursor_prev);

                }else if({currentSlide}.currentSlide == 1){

                    setcursorStyle(cursor_next);

                }
    
            } else {

                if({currentSlide}.currentSlide >= length - 1){

                    setcursorStyle(cursor_next);

                }

            }
        }

    };


    const handleSlideHover = (e) => {

        if (!sliderRef.current) return;

        const sliderNode = sliderRef.current.innerSlider.list;
        const rect = sliderNode.getBoundingClientRect();
        const clickX = e.clientX - rect.left;
        const slideWidth = rect.width / settings.slidesToShow;
        const length = sliderNode.children[0].children.length;

// console.log('tes')
        
        if(length > 1){
            if (clickX < slideWidth / 2) {

                if({currentSlide}.currentSlide == 0){
                    
                    
                    setcursorStyle(cursor_next);

                }else{

                    setcursorStyle(cursor_prev);

                }

            } else {

                if({currentSlide}.currentSlide >= length - 1){
                    
                    setcursorStyle(cursor_prev);

                }else{

                    setcursorStyle(cursor_next);

                }

            }
        }
    };


    const settings = {
        focusOnSelect: false,
        focusOnChange: false,
        accessibility: true,

        arrows: false,
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        afterChange: (slideIndex) => {
            setCurrentSlide(slideIndex);
        },
    };


      
    //  Animation
    const ref = useRef(null);
    const isInView = useInView(ref, {once: true});

    const mainControls = useAnimation();

    useEffect(() => {if (isInView){
        mainControls.start("visible")
    }}, [isInView])
    //  Animation


    return(
        <>
            <motion.div className="slide"
                    ref={ref}
                    variants={{
                        hidden: {opacity:0, y:25},
                        visible: {opacity:1, y:0}
                    }}
                    initial="hidden"
                    animate={mainControls}
                    transition={{duration:0.5, delay:0.2}}>
                                    
                <Slider {...settings} ref={sliderRef}>
                    {slideData.map((slide, index) => (
                        <div key={index} onClick={handleSlideClick} onMouseDown={posY}>
                        {slide.video ? (
                          <video autoPlay muted loop webkit-playsinline playsInline
                            src={slide.video}
                            className={cursorStyle}
                            style={{ width: "100%", zIndex: "99999999" }}
                            onMouseMove={handleSlideHover}
                            controls={false}
                          />
                        ) : (
                          <img
                            src={slide.image}
                            className={cursorStyle}
                            style={{ width: "100%" }}
                            onMouseMove={handleSlideHover}
                          />
                        )}
                      </div>
                    ))}
                </Slider>
    
                <div className="info_con">
                    <div className="client_name">{client}</div>
                    <div className="project_name">{project}</div>
                </div>
            </motion.div>
        </>

    )
}


export default SlideComp