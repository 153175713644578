import { Link, useMatch, useResolvedPath } from "react-router-dom";

const CustomLink = ({to, children, ...props}) => {
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({path: resolvedPath.pathname, end: true})

    return(

        <div className={isActive ? "list_element active" : "list_element inactive"}>
            <Link to={to} {...props}>{children}</Link>
        </div>
    )

}

export default CustomLink