import React from "react";
import { useEffect, useRef } from "react";

import { motion, useInView, useAnimation } from "framer-motion";

import SlideComp from './SlideComp.js'

import './ProjectSlide.css'
import './ProjectSlide_1280.css'
import './ProjectSlide_768.css'

import WorknCo_0 from '../../assets/project/1_col_off/ProductDesign/Work&Co/00.jpg'
import WorknCo_1 from '../../assets/project/1_col_off/ProductDesign/Work&Co/01.jpg'

import Amazon_0 from '../../assets/project/1_col_off/ProductDesign/Amazon/00.jpg'
import Amazon_1 from '../../assets/project/1_col_off/ProductDesign/Amazon/01.jpg'
import Amazon_2 from '../../assets/project/1_col_off/ProductDesign/Amazon/02.jpg'

import LG_0 from '../../assets/project/1_col_off/ProductDesign/LG/00.jpg'
import LG_1 from '../../assets/project/1_col_off/ProductDesign/LG/01.jpg'
import LG_2 from '../../assets/project/1_col_off/ProductDesign/LG/02.jpg'
import LG_3 from '../../assets/project/1_col_off/ProductDesign/LG/03.jpg'

import Vistaprint_0 from '../../assets/project/1_col_off/ProductDesign/Vistaprint/00.jpg'
import Vistaprint_1 from '../../assets/project/1_col_off/ProductDesign/Vistaprint/01.jpg'

import GoldmanSachs_0 from '../../assets/project/1_col_off/ProductDesign/GoldmanSachs/00.jpg'

import UnitedHealthcare_0 from '../../assets/project/1_col_off/ProductDesign/UnitedHealthcare/00.jpg'
import UnitedHealthcare_1 from '../../assets/project/1_col_off/ProductDesign/UnitedHealthcare/01.jpg'




const WorknCo = [
    { image: WorknCo_0 },
    { image: WorknCo_1 },
  ];

const Amazon = [
    { image: Amazon_0 },
    { image: Amazon_1 },
    { image: Amazon_2 },
  ];

const LG = [
    { image: LG_0 },
    { image: LG_1 },
    { image: LG_2 },
    { image: LG_3 },
  ];

const Vistaprint = [
    { image: Vistaprint_0 },
    { image: Vistaprint_1 }
  ];

const GoldmanSachs = [
    { image: GoldmanSachs_0 },
  ];

const UnitedHealthcare = [
    { image: UnitedHealthcare_0 },
    { image: UnitedHealthcare_1 },
  ];

  

const Product = () => {
    
    
    //  Animation
    const ref = useRef(null);
    const isInView = useInView(ref, {once: true});

    const mainControls = useAnimation();

    useEffect(() => {if (isInView){
        mainControls.start("visible")
    }}, [isInView])
    //  Animation


    return(
        <div className="section_container">
            <div className="grid_container_slider">
                    <SlideComp slideData={WorknCo} client = 'Work & Co' project = 'Pitch projects' cursor = 'dark'></SlideComp>
                    <SlideComp slideData={UnitedHealthcare} client = 'UnitedHealthcare' project = 'Onboarding' cursor = 'dark'></SlideComp>
                    <SlideComp slideData={Amazon} client = 'Amazon' project = 'Amazon Kids' cursor = 'dark'></SlideComp>
                    <SlideComp slideData={LG} client = 'LG' project = 'LG TV' cursor = 'light'></SlideComp>
                    <SlideComp slideData={Vistaprint} client = 'Vistaprint' project = 'Mailing Service' cursor = 'dark'></SlideComp>
                    <SlideComp slideData={GoldmanSachs} client = 'Goldman Sachs' project = 'Rewards' cursor = 'dark'></SlideComp>
                <motion.div className="slide"
                    ref={ref}
                    variants={{
                        hidden: {opacity:0, y:25},
                        visible: {opacity:1, y:0}
                    }}
                    initial="hidden"
                    animate={mainControls}
                    transition={{duration:0.5, delay:0.2}}>        
                </motion.div>
            </div>
        </div>

    )
}


export default Product