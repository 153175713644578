import React from "react";
import './Section.css'

import { useEffect, useRef } from "react";
import { motion, useInView, useAnimation, useIsPresent } from "framer-motion";

const Section = (props) => {
    const title = props.title
    const copy = props.copy
    const children = props.children
 

    //  Animation
    const ref = useRef(null);
    const isInView = useInView(ref, {once: true});

    const mainControls = useAnimation();

    useEffect(() => {if (isInView){
        mainControls.start("visible")
    }}, [isInView])
    //  Animation

    
    return(
        <>
            <div className="section_divider"></div>
            <div className="section_container">

                <motion.div className="grid_container"
                            ref={ref}
                            variants={{
                                hidden: {opacity:0, y:25},
                                visible: {opacity:1, y:0}
                            }}
                            initial="hidden"
                            animate={mainControls}
                            transition={{duration:0.5, delay:0.2}}>
                    <div className="divider"></div>
                    <div className="title">{title}</div>
                    <div className="copy">{copy}{children}
                    </div>
                </motion.div>
                
            </div>
        </>
    )
}

export default Section