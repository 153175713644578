import React from "react";
import './Landing.css';

import { useEffect, useRef } from "react";
import { motion, useInView, useAnimation, useIsPresent } from "framer-motion";

const Landing = () => {

    //  Animation
    const ref = useRef(null);
    const isInView = useInView(ref, {once: true});

    const mainControls = useAnimation();

    useEffect(() => {if (isInView){
        mainControls.start("visible")
    }}, [isInView])
    //  Animation


    return(
        <div className="landing_container">
            <motion.div 
                className="intro_container grid_container"
                    ref={ref}
                    variants={{
                        hidden: {opacity: 0, y: 50},
                        visible: {opacity: 1, y: 0}
                    }}
                    initial="hidden"
                    animate={mainControls}
                    transition={{duration: 0.8, delay: 0.2}}>

                <div className="title">Intro</div>
                <div className="copy">
                        Hello, I’m Hyeong Geun (Henry) Song, a designer based in New York, focusing on digital product and graphic design, previously served as a Senior Designer at Work & Co.
                    </div>
                
            </motion.div>
        </div>
    )
}


export default Landing